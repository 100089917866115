header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    text-align: center;
}

.header-container {
    
    height: 100%;
    position: relative;
    text-align: center;
}

/*===== Action =====*/

.action {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*===== Socials =====*/

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    font-size: 2rem;
    padding: 2rem;
    
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/*===== Image =====*/

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 20rem;
    height: 25rem;
    position: absolute;
    left: calc(50% - 10rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/*===== Scroll =====*/

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    padding: 2rem;
}

/*===== Media screens =====*/


@media screen and (max-width:1024px){
    header {
    height: 68vh;
    }
    }
    
    @media screen and (max-width:600px){
    header {
        height: 100vh;
    }
    .header_socials, .scroll_down{
        display: none;
    }
}